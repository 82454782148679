import React from 'react'

export const DownArrowFilledIcon = ({ style = {}, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{ width: 24, height: 24, ...style }}
    {...rest}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.68341017 9.8200922l3.54836853 4.2580423c.3535641.4242769.9841288.4816009 1.4084057.1280368a.99999878.99999878 0 00.1280369-.1280368l3.5483685-4.2580423c.1767821-.21213845.14812-.5274208-.0640184-.70420284C16.1627139 9.04100811 16.0494474 9 15.9324792 9H8.06752081c-.27614238 0-.5.22385763-.5.5 0 .11696825.04100811.2302347.11588936.3200922z"
    />
  </svg>
)
